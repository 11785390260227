import React from "react";
import styles from "./DataSecurity.module.css";
import "../../Assats//Fonts/Fonts.css";
import NewFooter from "../NewFooter/NewFooter";

export default function DataSecurity() {
  return (
    <div className={styles.Background}>
      <div className={styles.DataSecurity}>
        <h1>Datenschutzerklärung</h1>
        <div>
          <h2>1. Verantwortlicher</h2>
          <p>
            Verantwortlicherfür die Erhebung, Verarbeitung und Nutzung Ihrer
            personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist Iris
            Eiblmeier, Sabine Gehlen Teshome, HaarZweiOoh, Hackstraße 22, 70190
            Stuttgart Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer
            Daten durch uns nach Maßgabe dieser Datenschutzbestimmungen
            insgesamt oder für einzelne Maßnahmen widersprechen wollen, können
            Sie Ihren Widerspruch an den Verantwortlichen richten. Sie können
            diese Datenschutzerklärung jederzeit speichern und ausdrucken.
          </p>
          <h2>2. Allgemeine Zwecke der Verarbeitung</h2>
          <p>
            Wir verwenden personenbezogenen Daten zum Zweck des Betriebs der
            Website.
          </p>
          <h2>3. Welche Daten wir verwenden und warum</h2>
          <h3>3.1. Hosting</h3>
          <p>
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, Sicherheitsleistungen sowie technische
            Wartungsleistungen, die wir zum Zweck des Betriebs der
            Websiteeinsetzen. Hierbei verarbeiten wir, bzw. unser
            Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
            Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von
            Kunden, Interessenten und Besuchern dieser Websiteauf Grundlage
            unserer berechtigten Interessen an einer effizienten und sicheren
            Zurverfügungstellung unsererWebsitegem. Art. 6Abs. 1 S. 1 f)DSGVO
            i.V.m. Art. 28DSGVO.
          </p>
          <h3>3.2. Zugriffsdaten</h3>
          <p>
            Wir sammeln Informationen über Sie, wenn Sie diese Website nutzen.
            Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten
            und Ihre Interaktion mit uns undregistrieren Daten zu Ihrem Computer
            oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden
            Zugriff auf unsereWebsite(sogenannte Serverlogfiles). Zu den
            Zugriffsdaten gehören: Name und URL der abgerufenen Datei Datum und
            Uhrzeit des Abrufs ·übertragene Datenmenge Meldung über
            erfolgreichen Abruf (HTTP response code) Browsertyp und
            Browserversion Betriebssystem Referer URL(d.h. die zuvor besuchte
            Seite) Websites, die vom System des Nutzers über unsere Website
            aufgerufen werden Internet-Service-Provider des Nutzers IP-Adresse
            und der anfragende Provider Wir nutzen diese Protokolldaten ohne
            Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für
            statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und
            der Optimierung unsererWebsite, aber auch zur anonymen Erfassung der
            Anzahl der Besucher auf unserer Website (traffic) sowie zumUmfang
            und zur Art der Nutzung unserer Website und Dienste, ebenso zu
            Abrechnungszwecken, um die Anzahl der von Kooperationspartnern
            erhaltenen Clicks zu messen. Aufgrund dieser Informationen können
            wir personalisierte und standortbezogene Inhalte zur Verfügung
            stellen und den Datenverkehr analysieren, Fehler suchen und beheben
            und unsere Dienste verbessern. Hierin liegt auch unser berechtigtes
            Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO. Wir behalten uns vor,
            die Protokolldaten nachträglich zu überprüfen, wenn aufgrund
            konkreter Anhaltspunkte der berechtigte Verdacht einer
            rechtswidrigen Nutzung besteht. IP-Adressen speichern wir für einen
            begrenzten Zeitraum in den Logfiles, wenn dies für Sicherheitszwecke
            erforderlich oder für die Leistungserbringung oder die Abrechnung
            einer Leistung nötig ist, z. B. wenn Sie eines
            unsererAngebotenutzen. Nach Abbruch des Vorgangs der Bestellung oder
            nach Zahlungseingang löschen wir die IP-Adresse, wenn diese für
            Sicherheitszwecke nicht mehr erforderlich ist. IP-Adressen speichern
            wir auch dann, wenn wir den konkreten Verdacht einer Straftat im
            Zusammenhang mit der Nutzung unserer Website haben. Außerdem
            speichern wir als Teil Ihres Accounts das Datum Ihres letzten
            Besuchs (z.B. bei Registrierung, Login, Klicken von Links etc.).
          </p>
          <h3>3.3. Cookies</h3>
          <p>
            Wir verwenden sogenannte Session-Cookies, um unsereWebsite zu
            optimieren. Ein Session-Cookie ist eine kleine Textdatei, die von
            den jeweiligen Servern beim Besuch einer Internetseite verschickt
            und auf Ihrer Festplatte zwischengespeichert wird. Diese Dateials
            solcheenthält eine sogenannte Session-ID, mit welcher sich
            verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
            zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
            Sie auf unsere Website zurückkehren. Diese Cookies werden gelöscht,
            nachdem Sie Ihren Browser schließen. Sie dienen z. B. dazu, dass Sie
            die Warenkorbfunktion über mehrere Seiten hinweg nutzen können. Wir
            verwenden in geringem Umfang auch persistente Cookies (ebenfalls
            kleine Textdateien, die auf Ihrem Endgerät abgelegt werden), die auf
            Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim
            nächsten Besuch wiederzuerkennen. Diese Cookies werden auf Ihrer
            Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit
            von allein. Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre.So können
            wir Ihnen unser Angebot nutzerfreundlicher, effektiver und sicherer
            präsentierenund Ihnen beispielsweise speziell auf Ihre Interessen
            abgestimmte Informationen auf der Seite anzeigen. Unser berechtigtes
            Interesse an der Nutzung der Cookies gemäß Art 6 Abs. 1 S. 1 f)
            DSGVO liegt darin, unsere Website nutzerfreundlicher, effektiver und
            sicherer zu machen. In den Cookies werden etwa folgende Daten und
            Informationen gespeichert: Log-In-Informationen Spracheinstellungen
            eingegebene Suchbegriffe Informationen über die Anzahl der Aufrufe
            unserer Website sowie Nutzung einzelner Funktionen unseres
            Internetauftritts. Bei Aktivierung des Cookies wird diesem eine
            Identifikationsnummer zugewiesen und eine Zuordnung Ihrer
            personenbezogenen Daten zu dieser Identifikationsnummer wird nicht
            vorgenommen. Ihr Name, Ihre IP-Adresse oder ähnliche Daten, die eine
            Zuordnung des Cookies zu Ihnen ermöglichen würden, werden nicht in
            den Cookie eingelegt. Auf Basis der Cookie-Technologie erhalten wir
            lediglich pseudonymisierte Informationen, beispielsweise darüber,
            welche Seiten unseres Shops besucht wurden, welche Produkte
            angesehen wurden, etc. Sie können Ihren Browser so einstellen, dass
            Sie über das Setzen von Cookies vorab informiert werden und im
            Einzelfall entscheiden können, ob Sie die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen, oder dass Cookies
            komplett verhindert werden. Dadurch kann die Funktionalität der
            Website eingeschränkt werden.
          </p>
          <h2>4. Ihre Rechte als von der Datenverarbeitung Betroffener</h2>
          <p>
            Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte
            bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte
            geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder
            per Post unter eindeutiger Identifizierung Ihrer Person an die in
            Ziffer 1genannte Adresse. Nachfolgend finden Sie eine Übersicht über
            Ihre Rechte.
          </p>
          <h3>4.1. Recht auf Bestätigung und Auskunft</h3>
          <p>
            Sie haben das Recht auf eine übersichtliche Auskunft über die
            Verarbeitung Ihrer personenbezogenen Daten. Im Einzelnen: Sie haben
            jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten,
            ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist
            dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche
            Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten
            nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht
            ein Recht auf folgende Informationen: die Verarbeitungszwecke; die
            Kategorien personenbezogener Daten, die verarbeitet werden; die
            Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen; falls möglich,die geplante
            Dauer, für die die personenbezogenen Daten gespeichert werden, oder,
            falls dies nicht möglich ist, die Kriterien für die Festlegung
            dieser Dauer; das Bestehen eines Rechts auf Berichtigung oder
            Löschung der Sie betreffenden personenbezogenen Daten oder auf
            Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung; das Bestehen eines
            Beschwerderechts bei einer Aufsichtsbehörde; wenn die
            personenbezogenen Daten nicht bei Ihnenerhoben werden, alle
            verfügbaren Informationen über die Herkunft der Daten; das Bestehen
            einer automatisierten Entscheidungsfindung einschließlich Profiling
            gemäß Art.22Abs.1 und 4 DSGVO und – zumindest in diesen Fällen –
            aussagekräftige Informationen über die involvierte Logik sowie die
            Tragweite und die angestrebten Auswirkungen einer derartigen
            Verarbeitung für Sie. Werden personenbezogene Daten an ein Drittland
            oder an eine internationale Organisation übermittelt, so haben
            Siedas Recht, über die geeigneten Garantien gemäß Art.46 DSGVO im
            Zusammenhang mit der Übermittlung unterrichtet zu werden.
          </p>
          <h3>4.2. Recht auf Berichtigung</h3>
          <p>
            Sie haben das Recht, von uns die Berichtigung und ggf. auch
            Vervollständigung Sie betreffender personenbezogener Daten zu
            verlangen. Im Einzelnen: Sie habendas Recht, von unsunverzüglich die
            Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu
            verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben
            Siedas Recht, die Vervollständigung unvollständiger
            personenbezogener Daten – auch mittels einer ergänzenden Erklärung –
            zu verlangen.
          </p>
          <h3>4.3. Recht auf Löschung ("Recht auf Vergessenwerden")</h3>
          <p>
            In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende
            personenbezogene Daten zu löschen. Im Einzelnen: Sie habengemäß Art.
            17 Abs. 1 DSGVO das Recht, von unszu verlangen, dass Sie betreffende
            personenbezogene Daten unverzüglich gelöscht werden, und wir sind
            verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern
            einer der folgenden Gründe zutrifft: Die personenbezogenen Daten
            sind für die Zwecke, für die sie erhoben oder auf sonstige Weise
            verarbeitet wurden, nicht mehr notwendig. Sie widerrufen Ihre
            Einwilligung, auf die sich die Verarbeitung gemäß Art.6 Abs.1S. 1
            a)DSGVO oder Art.9 Abs.2 a)DSGVO stützte, und es fehlt an einer
            anderweitigen Rechtsgrundlage für die Verarbeitung. Sie legen gemäß
            Art.21 Abs.1 DSGVO Widerspruch gegen die Verarbeitung ein und es
            liegen keine vorrangigen berechtigten Gründe für die Verarbeitung
            vor, oder Sie legen gemäß Art.21 Abs.2 DSGVO Widerspruch gegen die
            Verarbeitung ein. Die personenbezogenen Daten wurden unrechtmäßig
            verarbeitet. Die Löschung der personenbezogenen Daten ist zur
            Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
            dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen. Die
            personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art.8 Abs.1 DSGVO erhoben. Haben
            wirdie personenbezogenen Daten öffentlich gemacht und sind wirgemäß
            Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so treffen
            wirunter Berücksichtigung der verfügbaren Technologie und der
            Implementierungskosten angemessene Maßnahmen, auch technischer Art,
            um für die Datenverarbeitung Verantwortliche, die die
            personenbezogenen Daten verarbeiten, darüber zu informieren, dass
            Sievon ihnen die Löschung aller Links zu diesen personenbezogenen
            Daten oder von Kopien oder Replikationen dieser personenbezogenen
            Daten verlangt haben.
          </p>
          <h3>4.4. Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            In einer Reihe von Fällen sind Sie berechtigt, von uns eine
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Im Einzelnen: Sie habendas Recht, von unsdie
            Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden
            Voraussetzungen gegeben ist: die Richtigkeit der personenbezogenen
            Daten wird von Ihnenbestritten, und zwar für eine Dauer, die es
            unsermöglicht, die Richtigkeit der personenbezogenen Daten zu
            überprüfen, die Verarbeitung unrechtmäßig ist und Siedie Löschung
            der personenbezogenen Daten ablehnten und stattdessen die
            Einschränkung der Nutzung der personenbezogenen Daten verlangthaben;
            wir die personenbezogenen Daten für die Zwecke der Verarbeitung
            nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder Sie
            Widerspruch gegen die Verarbeitung gemäß Art.21 Abs.1 DSGVO
            eingelegt haben, solange noch nicht feststeht, ob die berechtigten
            Gründe unseres Unternehmens gegenüber den Ihren überwiegen.{" "}
          </p>
          <h3>4.5. Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, Sie betreffende personenbezogene Daten
            maschinenlesbar zu erhalten, zu übermitteln, oder von uns
            übermitteln zu lasen. Im Einzelnen: Sie haben das Recht, die Sie
            betreffenden personenbezogenen Daten, die Sie uns bereitgestellt
            haben, in einem strukturierten, gängigen und maschinenlesbaren
            Format zu erhalten, und Sie haben das Recht, diese Daten einem
            anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln,
            sofern die Verarbeitung auf einer Einwilligung gemäß Art.6 Abs.1S.
            1a)DSGVO oder Art.9 Abs.2 a)DSGVO oder auf einem Vertrag gemäß Art.6
            Abs.1S. 1b)DSGVO beruht und die Verarbeitung mithilfe
            automatisierter Verfahren erfolgt. Bei der Ausübung Ihres Rechts auf
            Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu
            erwirken, dass die personenbezogenen Daten direkt von unseinem
            anderen Verantwortlichen übermittelt werden, soweit dies technisch
            machbar ist.
          </p>
          <h3>4.6. Widerspruchsrecht</h3>
          <p>
            Sie haben das Recht, aucheiner rechtmäßigen Verarbeitung Ihrer
            personenbezogenen Daten durch uns zu widersprechen, wenn sich dies
            aus Ihrer besonderen Situation begründet und unsere Interessen an
            der Verarbeitung nicht überwiegen. Im Einzelnen: Sie haben das
            Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
            Daten, die aufgrund von Art.6 Abs.1S. 1e)oder f)DSGVO erfolgt,
            Widerspruch einzulegen; dies gilt auch für ein auf diese
            Bestimmungen gestütztes Profiling. Wir verarbeiten die
            personenbezogenen Daten nicht mehr, es sei denn, wir können
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
            Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen. Werden personenbezogene Daten von uns
            verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
            jederzeit Widerspruch gegen die Verarbeitung Sie betreffender
            personenbezogener Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht. Sie haben das Recht, aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, gegen die Sie
            betreffende Verarbeitung Sie betreffender personenbezogener Daten,
            die zu wissenschaftlichen oder historischen Forschungszwecken oder
            zu statistischen Zwecken gemäß Art.89 Abs.1 DSGVO erfolgt,
            Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur
            Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
            erforderlich.
          </p>
          <h3>4.7. Automatisierte Entscheidungen einschließlich Profiling</h3>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnengegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Eine automatisierte Entscheidungsfindung auf der
            Grundlage der erhobenen personenbezogenen Daten findet nicht statt.
          </p>
          <h3>
            4.8. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
          </h3>
          <p>
            Sie haben das Recht, eine Einwilligung zur Verarbeitung
            personenbezogener Daten jederzeit zu widerrufen.
          </p>
          <h3>4.9. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
          <p>
            Sie habendas Recht auf Beschwerde bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sieder
            Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten rechtswidrig ist.
          </p>
          <h2>5. Datensicherheit</h2>
          <p>
            Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden
            Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.
            Ihre persönlichen Daten werden bei uns verschlüsselt übertragen.
            Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir
            nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich. Zur Sicherung Ihrer Daten unterhalten wir technische und
            organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die
            wir immer wieder dem Stand der Technik anpassen. Wir gewährleisten
            außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur
            Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können
            nicht ausgeschlossen werden. Die von uns verwendeten Server werden
            regelmäßig sorgfältig gesichert.
          </p>
          <h2>
            6. Weitergabe von Daten an Dritte, keine Datenübertragung ins
            Nicht-EU-Ausland
          </h2>
          <p>
            Eine Datenübertragung an Stellen oder Personen außerhalb der EU
            außerhalb des in dieser Erklärung in Ziffer 4 genannten Falls findet
            nicht statt und ist nicht geplant.
          </p>
          <h2>7. Google Maps</h2>
          <p>
            Diese Seite nutzt über eine API den Kartendienst Google Maps.
            Anbieter ist die Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Irland. Zur Nutzung der Funktionen von Google Maps
            ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss
            auf diese Datenübertragung.Mehr Informationen zum Umgang mit
            Nutzerdaten finden Sie in der Datenschutzerklärung von Google:
            https://www.google.de/intl/de/policies/privacy/
          </p>
        </div>
      </div>
      <NewFooter mainPage={false} />
    </div>
  );
}
